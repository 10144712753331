import React from 'react';
import Layout from '../components/Layout';
import howItWorksBanner from '../images/how_it_works_banner.svg';
import basket from '../images/basket_placeholder.svg';
import car from '../images/car.svg';
import smile from '../images/smile.svg';

const gridItems = [
    {
        heading: "SHOPPING",
        imgSrc: basket,
        subheading: "Sunday - Wednesday",
        imgAlt: "Shopping cart",
        content: (
            <p>The till marketplace opens every <strong>Sunday at 2:00 PM</strong> and closes on <strong>Wednesday at 5:00 PM</strong>. Log in to 
                your till account to create custom orders with food products sourced from any of our numerous Alabama farms.
            </p>
        )
    },
    {
        heading: "DELIVERY",
        imgSrc: car,
        subheading: "Saturday",
        imgAlt: "Car",
        content: (
            <p>Our favorite day! Your bag will be delivered and left on your doorstep in an insulated tote bag on <strong>Saturday between 8:00 AM and noon</strong>.
            You can conveniently track your delivery and estimated drop time starting Saturday morning.</p>
        )
    },
    {
        heading: "ENJOYMENT",
        imgSrc: smile,
        subheading: "Every day",
        imgAlt: "Smiley face",
        content: (
            <p>Enjoy your locally sourced food as you please. Check back weekly for new foods and recipe ideas, and don't forget to add the farms and food products you like best to your list of favorites!</p>
        )
    }
];

const faqs = [
    [
        "What do I do with my insulated tote bag and ice packs from last week’s order?",
        "Simply leave your till bag, with the ice packs on your porch Saturday before 8:00 AM. The ice packs do not need to be frozen. We’ll grab the till bag and ice packs and replace them with a bag full of farm-fresh and cold ice-packs."
    ],
    [
        "How do I get an insulated tote bag?",
        "There is a one-time fee associated with your first order that will include 1 till bag to be recycled with each future order."
    ],
    [
        "How often can I order?",
        "Order as often as you would like. Most members order each week for Saturday delivery, but feel free to skip weeks as desired."
    ],
    [
        "Can I add more to my order throughout the week?",
        "Absolutely! As recipe inspiration hits you throughout the week, feel free to add anything you like. Simply create a new order, then checkout -- you will not be charged for a delivery fee as long as you enter the same delivery address as your original order. Just remember to place your final order before 5:00 PM Wednesday!"
    ],
    [
        "What is required of me for delivery?",
        <>To ensure freshness and a successful delivery we ask that you: <br />1. Leave your insulated till bag with ice packs on your porch prior to 8:00 AM Saturday. <br />2. Understand that your delivery will arrive between 8:00 AM - 12:00 PM on Saturdays. <br/>To ensure freshness, please have it brought inside within an hour of receiving your verification email or text.</>
    ],
    [
        "When do I have to submit my order?",
        "Each week you have from Sunday-Wednesday at 5:00 PM to create and submit your week’s till order. You are unable to submit an order for Saturday delivery after Wednesday at 5:00 PM."
    ],
    [
        "Where do you deliver to?",
        <>Currently, we deliver to 12 Birmingham ZIP codes on Saturdays. Those ZIP codes are: <strong>35213, 35209, 35243, 35242, 35223, 35216, 35222, 35233, 35203, 35205, 35226, and 35244.</strong> We’re continually working to add ZIP codes and cover more of Birmingham.</>
    ],
    [
        "How do I tip my delivery driver?",
        "You can conveniently tip online when checking out. Our drivers take part in tip share, so your specific driver will get a portion of your tip. We appreciate you! "
    ],
    [
        "Will you continually be adding farms and selections for each farm?",
        "Not only do we continually update our farm’s selections as their harvests ripen, but we will continue to add new farms to till to enhance selection on the site."
    ],
    [
        "Is there a mobile app?",
        "Not yet, though our website has been optimized for mobile viewing."
    ],
    [
        "Is there an order minimum or maximum?",
        "No. You can order as little as you would like, or as much as you would like! Delivery is a flat rate charge regardless of order size."
    ],
    [
        "Will you deliver on days other than Saturday?",
        "Because your farmers are harvesting your order just days prior to your pickup, we are currently limiting delivery to Saturdays. We’re working on adding a weekday option soon."
    ]
]

export default () => {
    return (
        <Layout pageTitle="How till works" ogUrl="/how-till-works/">
          <div className='big-layout-heading'>
              <div style={{backgroundImage: `url(${howItWorksBanner})`}} className='content-container'>
                  <h3>How shopping with till works</h3>
                  <p>Shopping with till makes supporting local farmers and purveyors easy. Check out the simple steps below to see just how easy!</p>
              </div>
              <div className='svg-container'>
                  <svg xmlns="http://www.w3.org/2000/svg" version="1.1" preserveAspectRatio="none" viewBox="0 0 100 20" width="100%" height="100%">
                      <path fill='currentColor' d="M0 0C0.33 0.13 0.53 0.21 0.62 0.24C32.55 12.93 68.13 12.84 100 0C100 0 100 0 100 0L100 20L0 20L0 0Z" />
                  </svg>
              </div>
          </div>
            <div className='layout-container'>
                <h3 className='margin-top-4 text-center text-primary' style={{letterSpacing: 3}}>Farm to door is simple with till</h3>
                <div className='how-it-works-container'>
                    <div className='grid-container add-width-1'>
                        {gridItems.map(({heading, subheading, imgAlt, imgSrc, content}, index) => {
                            return (
                                <div className='grid-item-3' key={`item-${index}`}>
                                    <div className={`content item-${index+1}`}>
                                        <h3>{heading}</h3>
                                        <img src={imgSrc} alt={imgAlt} />
                                        <h4>{subheading}</h4>
                                        {content}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='layout-page-section'>
                    <h3 className='text-center margin-bottom-4'>Frequently asked questions</h3>
                    {faqs.map((item, index) => {
                        return (
                            <React.Fragment key={`faq-${index}`}>
                                <h5>{item[0]}</h5>
                                <p>{item[1]}</p>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        </Layout>
    )
}